import {
  ProductsList,
  PageLoading,
} from "@royalcanin-be-partner-portal/ui-kit";
import React, { useState } from "react";
import { Layout } from "../../components/Layout";
import { useProducts, useProductCategories } from "../../lib/hooks/useProducts";
import { CheckPermission } from "../../components/CheckPermission";
import { withPage } from "../../lib/withPage";

const Products = () => {
  const [currentCategoryId, setCurrentCategoryId] = useState<
    string | undefined
  >();
  const { items: collections, loading } = useProducts({
    categoryId: currentCategoryId,
  });
  const {
    items: categories,
    loading: loadingCategories,
  } = useProductCategories();
  const isLoading = loading || loadingCategories;

  return (
    <Layout>
      <CheckPermission permissions={"products"} redirect>
        {isLoading && <PageLoading />}
        <ProductsList
          products={collections.map(collection => ({
            ...collection,
            image: collection.imageSmall || "",
            url: `/products/detail?reference=${collection.reference}`,
          }))}
          categories={categories.map(category => ({
            label: category.label,
            value: category.id,
          }))}
          onCategoryChange={categoryId => {
            if (!categoryId || categoryId === "all") {
              setCurrentCategoryId(undefined);
            } else {
              setCurrentCategoryId(categoryId);
            }
          }}
          categoryId={currentCategoryId}
        />
      </CheckPermission>
    </Layout>
  );
};

export default withPage(Products);
