import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useLocale } from "../locale";
import { Locale } from "../../../../../__generated__/globalTypes";
import {
  ProductCategoriesVariables,
  ProductCategories,
} from "./__generated__/ProductCategories";
import { Products, ProductsVariables } from "./__generated__/Products";
import { Product, ProductVariables } from "./__generated__/Product";
import {
  ProductSummaryFragment,
  ProductBenefitsFragment,
  ProductLinksFragment,
  ProductRelatedFragment,
  ProductListItemFragment,
  ProductPackagingFragment,
} from "@royalcanin-be-partner-portal/ui-kit";

const ProductsQuery = gql`
  query Products($token: String!, $locale: Locale!, $categoryId: String) {
    products(token: $token, locale: $locale, categoryId: $categoryId) {
      result {
        reference
        ...ProductListItemFragment
        hasImage
        imageSmall
      }
      error {
        message
        code
      }
    }
  }
  ${ProductListItemFragment}
`;

export const useProducts = ({
  categoryId,
}: {
  categoryId?: string;
} = {}) => {
  const token = useUserToken();
  const locale = useLocale();
  const { data, ...res } = useQuery<Products, ProductsVariables>(
    ProductsQuery,
    {
      skip: !token,
      variables: {
        token,
        locale: Locale[locale],
        categoryId,
      },
    },
  );

  return {
    ...res,
    data,
    items: data?.products.result || [],
  };
};

const ProductCategoriesQuery = gql`
  query ProductCategories($token: String!, $locale: Locale!) {
    productCategories(token: $token, locale: $locale) {
      result {
        id
        label
      }
      error {
        message
        code
      }
    }
  }
`;

export const useProductCategories = () => {
  const token = useUserToken();
  const locale = useLocale();
  const { data, ...res } = useQuery<
    ProductCategories,
    ProductCategoriesVariables
  >(ProductCategoriesQuery, {
    skip: !token,
    variables: {
      token,
      locale: Locale[locale],
    },
  });

  return {
    ...res,
    data,
    items: data?.productCategories.result || [],
  };
};

const ProductQuery = gql`
  query Product($token: String!, $locale: Locale!, $reference: String!) {
    product(token: $token, locale: $locale, reference: $reference) {
      result {
        reference
        ...ProductSummaryFragment
        ...ProductBenefitsFragment
        ...ProductLinksFragment
        ...ProductRelatedFragment
        ...ProductPackagingFragment
      }
      error {
        message
        code
      }
    }
  }
  ${ProductSummaryFragment}
  ${ProductBenefitsFragment}
  ${ProductLinksFragment}
  ${ProductRelatedFragment}
  ${ProductPackagingFragment}
`;

export const useProduct = (reference?: string | null) => {
  const token = useUserToken();
  const locale = useLocale();
  const { data, ...res } = useQuery<Product, ProductVariables>(ProductQuery, {
    skip: !token || !reference,
    variables: {
      token,
      locale: Locale[locale],
      reference: reference || "",
    },
  });

  return {
    ...res,
    data,
    item: data?.product.result || undefined,
  };
};
